// @owner ClientPlatform
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/label-has-associated-control */

import type {FC, MouseEvent, MouseEventHandler, PropsWithChildren} from 'react';
import React, {useState} from 'react';
import {classNames} from '../../../helpers/classNameHelpers';
import {SigninPage, type SwitchToSigninPage} from '../../helpers/pageHelpers';
import {
  findBaseUrl,
  isSignupSupported,
  isSsoSupported,
  pathToRedirectTo,
  userDidSignIn,
} from '../../../helpers/signupHelpers';
import {StyledParagraph, StyledAnchor, StyledRow, StyledThirdPartySigninRow} from '../../commonSigninStyles';
import {styled} from 'styled-components';
import {SigninInput} from '../../components/signinInput/signinInput';
import {SigninButton} from '../../components/signinButton/signinButton';
import {requester} from '../../../helpers/requestHelpers';

const StyledLinkLabels = styled(StyledParagraph)`
  font-size: 12px;
`;

const StyledCopyLinkLabels = styled(StyledLinkLabels)`
  text-align: center;
`;

const StyledErrorMessage = styled.div`
  width: 100%;
  font-size: 1em;
  color: #ff0c0c;
  line-height: 1.5;
  text-align: center;
  padding: 6px 5%;
`;

interface LoginProps {
  switchToSigninPage: SwitchToSigninPage;
  googleSignin: MouseEventHandler<HTMLElement>;
  officeSignin: MouseEventHandler<HTMLElement>;
}

interface ForgotPasswordWindow extends Window {
  analytics?: {
    identify: (email: string) => void;
    track: (eventName: string) => void;
  };
}

function findLoginWindow() {
  return window as ForgotPasswordWindow;
}

export const Login: FC<PropsWithChildren<LoginProps>> = ({switchToSigninPage, googleSignin, officeSignin}) => {
  const [error, setError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [twoFactorAuthToken, setTwoFactorAuthToken] = useState('');
  const [isSending, setIsSending] = useState(false);

  async function signin() {
    setIsSending(true);
    setError('');

    try {
      await requester.post('/api/1/signin', {
        email,
        password,
        two_factor_auth_token: twoFactorAuthToken,
      });

      const window = findLoginWindow();
      if (window.analytics) {
        window.analytics.identify(email);
        window.analytics.track('clicked_signin_app');
      }

      // Use the correct method depending on if the OSX app accept the base URL as param or not.
      userDidSignIn(pathToRedirectTo(), findBaseUrl());
    } catch (e) {
      setIsSending(false);
      setTwoFactorAuthToken('');

      const {response} = e;
      const {data} = response;

      if (response.status === 401) {
        setError(data.status);
        setErrorMessage(data.message);
        return;
      }

      // 404: email not found
      // 403: bad_password OR SSO is enabled
      // 429: rate_limit
      // 450: 2fa_auth_token_missing
      if ([404, 403, 429, 450].includes(response.status)) {
        const err = data.reason || data.status;

        // If company enforced OAuth login, switch to OAuth
        if (err === 'oauth_only') {
          switchToSigninPage(SigninPage.OAUTH_ONLY);
          return;
        }

        setError(err);
        return;
      }

      setError('unknown');
    }
  }

  function openSignup(event: MouseEvent) {
    event.preventDefault();
    event.stopPropagation();

    window.open('https://front.com/signup');
  }

  return (
    <form
      name="signinForm"
      onSubmit={(e) => {
        e.preventDefault();
        signin();
      }}
    >
      <StyledRow>
        <SigninInput
          value={email}
          id="email-login"
          label="Email"
          type="email"
          name="email"
          placeholder="email address"
          required
          autoFocus
          onChange={setEmail}
          error={error === 'not_found' ? 'Invalid email or password' : undefined}
        />
      </StyledRow>
      <StyledRow>
        <SigninInput
          value={password}
          id="password-login"
          label="Password"
          type="password"
          name="password"
          placeholder="password"
          required
          onChange={setPassword}
          error={findPasswordErrorText(error)}
        />
      </StyledRow>
      {maybeRenderTwoFactorAuth(error, twoFactorAuthToken, setTwoFactorAuthToken)}
      {maybeRenderAuthRequired(error, errorMessage)}
      <StyledRow>
        <SigninButton testId="signin" type="submit" disabled={isSending}>
          Sign in
        </SigninButton>
      </StyledRow>
      <StyledRow>
        {maybeRenderGetStarted(openSignup)}
        {maybeRenderSsoSignin(switchToSigninPage)}
        <StyledParagraph>
          <StyledAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              switchToSigninPage(SigninPage.FORGOT_PASSWORD);
            }}
          >
            Forgot password?
          </StyledAnchor>
        </StyledParagraph>
      </StyledRow>
      <StyledThirdPartySigninRow>
        <SigninButton type="button" onClick={googleSignin}>
          Sign in with Google
        </SigninButton>
        <SigninButton type="button" onClick={officeSignin}>
          Sign in with Office 365
        </SigninButton>
      </StyledThirdPartySigninRow>
      <StyledRow>
        <StyledCopyLinkLabels>
          <StyledAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open('https://front.com/legal/privacy-notice');
            }}
          >
            Privacy Notice
          </StyledAnchor>
        </StyledCopyLinkLabels>
        <StyledCopyLinkLabels>
          <StyledAnchor
            href="#"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              window.open(
                'https://front.com/legal/front-products-and-services-privacy-notice#google_user_data_privacy_notice'
              );
            }}
          >
            Google User Data Privacy Notice
          </StyledAnchor>
        </StyledCopyLinkLabels>
      </StyledRow>
    </form>
  );
};

function findPasswordErrorText(error: string) {
  switch (error) {
    case 'bad_password':
      return 'Invalid email or password';
    case 'rate_limit':
      return 'Too many attempts, wait a little';
    case 'sso_enabled':
      return 'Your company has enabled SSO';
    case 'unknown':
      return 'Unknown error';
    default:
      return undefined;
  }
}

function maybeRenderTwoFactorAuth(
  error: string,
  twoFactorAuthToken: string,
  setTwoFactorAuthToken: (value: string) => void
) {
  if (error !== 'missing_two_factor_auth' && error !== 'invalid_two_factor_auth') {
    return null;
  }

  return (
    <StyledRow>
      <SigninInput
        id="token-login"
        type="text"
        label="Two factor code"
        name="token"
        placeholder="Two factor code"
        required
        autoFocus
        value={twoFactorAuthToken || ''}
        onChange={setTwoFactorAuthToken}
        error={error === 'invalid_two_factor_auth' ? 'This code is invalid' : undefined}
      />
    </StyledRow>
  );
}

function maybeRenderAuthRequired(error: string, errorMessage: string) {
  if (error !== 'authentication_required') {
    return null;
  }

  return (
    <StyledRow>
      <StyledErrorMessage
        className={classNames({
          'error-message': true,
          'text-small': true,
          visible: error === 'authentication_required',
        })}
      >
        {errorMessage}
      </StyledErrorMessage>
    </StyledRow>
  );
}

function maybeRenderGetStarted(openSignup: (event: MouseEvent) => void) {
  if (!isSignupSupported()) {
    return null;
  }

  return (
    <StyledCopyLinkLabels>
      Don&#39;t have an account?{' '}
      <StyledAnchor href="#" onClick={openSignup}>
        Try for Free
      </StyledAnchor>
    </StyledCopyLinkLabels>
  );
}

function maybeRenderSsoSignin(switchToSigninPage: SwitchToSigninPage) {
  if (!isSsoSupported()) {
    return null;
  }

  return (
    <StyledCopyLinkLabels>
      Using SSO?{' '}
      <StyledAnchor
        id="sso-signin-link"
        href="#"
        onClick={(e) => {
          e.preventDefault();
          switchToSigninPage(SigninPage.INIT_SIGNIN);
        }}
      >
        Sign in with SSO
      </StyledAnchor>
    </StyledCopyLinkLabels>
  );
}
