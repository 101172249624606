// @owner ClientPlatform

import React from 'react';
import type {FC, MouseEventHandler, PropsWithChildren} from 'react';
import {styled} from 'styled-components';

const StyledButton = styled.button`
  display: block;
  background: #001b38;
  box-shadow: 0px 2px 9px rgba(0, 0, 0, 0.13);
  border-radius: 48px;
  color: white;
  width: 270px;
  margin: 0 auto;
  font-weight: 700;
  font-size: 16px;
  line-height: 113%;
  padding: 15px 0;
  transition: all 150ms ease 0s;

  &:hover {
    background: #cc0049;
  }

  &:disabled {
    box-shadow: 0px 0px 0px #fff;
    background: rgba(0, 0, 0, 0.1);
    color: #fff;

    &:hover {
      background: rgba(0, 0, 0, 0.1);
    }
  }
`;

interface SigninButtonProps {
  type: 'button' | 'submit' | 'reset';
  disabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  testId?: string;
}

export const SigninButton: FC<PropsWithChildren<SigninButtonProps>> = ({type, disabled, onClick, children, testId}) => {
  return (
    <StyledButton type={type} disabled={disabled} onClick={onClick} data-testid={testId}>
      {children}
    </StyledButton>
  );
};
